<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Consult your solubility rules to decide the solubility of each of the following:
      </p>
      <p class="mb-2">
        <chemical-latex content="Cu(CH3COO)2" />
        <v-select
          v-model="inputs.input1"
          style="display: inline-block; max-width: 200px"
          class="ml-2"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <chemical-latex content="CaSO4" />
        <v-select
          v-model="inputs.input2"
          style="display: inline-block; max-width: 200px"
          class="ml-2"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <chemical-latex content="Sr(OH)2" />
        <v-select
          v-model="inputs.input3"
          style="display: inline-block; max-width: 200px"
          class="ml-2"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <chemical-latex content="BaSO4" />
        <v-select
          v-model="inputs.input4"
          style="display: inline-block; max-width: 200px"
          class="ml-2"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <chemical-latex content="NiCO3" />
        <v-select
          v-model="inputs.input5"
          style="display: inline-block; max-width: 200px"
          class="ml-2"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemCPP1210LPrelabEquations1',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      items: [
        {text: 'insoluble', value: 'insoluble'},
        {text: 'slightly soluble', value: 'slightlySoluble'},
        {text: 'soluble', value: 'soluble'},
      ],
    };
  },
};
</script>
<style scoped></style>
